import {
    FETCH_CARS,
    FETCH_CARS_SUCCESS,
    FETCH_CARS_FAILED,
    EDIT_CAR
  } from "../store/types";
  import store from '../store/store';
  import { firebase } from '../config/configureFirebase';
  import { onValue, update, set, child, remove, push } from "firebase/database";
  import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
  
  export const fetchCars = () => (dispatch) => {
  
    const {
        carsRef
    } = firebase;
  
    dispatch({
      type: FETCH_CARS,
      payload: null
    });

    const userInfo = store.getState().auth.profile;

    onValue(carsRef(userInfo.uid, userInfo.usertype), snapshot => {
      if (snapshot.val()) {
        let data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_CARS_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_CARS_FAILED,
          payload: store.getState().languagedata.defaultLanguage.no_cars
        });
      }
    });
  };
  
  export const editCar = (car, method) => async (dispatch) => {
    const {
      singleUserRef,
      carAddRef, 
      carEditRef,
      carImage,
      carInsuranceImage
    } = firebase;
    dispatch({
      type: EDIT_CAR,
      payload: { method, car }
    });
  
    if (method === 'Add') {
      push(carAddRef, car);
    } else if (method === 'Delete') {
      remove(carEditRef(car.id));
    } else if (method === 'UpdateImage') {
      await uploadBytesResumable(carImage(car.id),car.car_image);
      let image = await getDownloadURL(carImage(car.id));
      let data = car;
      data.car_image = image;
      set(carEditRef(car.id), data);
      if(car.active && car.driver){
        update(singleUserRef(car.driver), {
          updateAt: new Date().getTime(),
          car_image: image
        });
      }   
    } else if (method === 'UpdateInsuranceImage') {
      await uploadBytesResumable(carInsuranceImage(car.id),car.insuranceImage);
      let image = await getDownloadURL(carInsuranceImage(car.id));
      let data = car;
      data.insuranceImage = image;
      set(carEditRef(car.id), data);
      if(car.active && car.driver){
        update(singleUserRef(car.driver), {
          updateAt: new Date().getTime(),
          insuranceImage: image
        });
      }   
    }else {
      set(carEditRef(car.id), car);
    }
  };

  export const updateUserCarWithImage = (newData, blob) => (dispatch) => {
    const { auth, carAddRef, singleUserRef, carImage, carInsuranceImage } = firebase;

    const carId = push(carAddRef).key;  
 
    const carImageUpload = uploadBytesResumable(carImage(carId), blob?.car_image)
        .then(() => { 
            return getDownloadURL(carImage(carId));
        })
        .catch((error) => {
            console.error("Error uploading car image:", error);  
            throw error;
        });
 
    const insuranceImageUpload = uploadBytesResumable(carInsuranceImage(carId), blob?.insuranceImage)
        .then(() => { 
            return getDownloadURL(carInsuranceImage(carId));
        })
        .catch((error) => {
            console.error("Error uploading insurance image:", error); 
            throw error;
        });
 
    Promise.all([carImageUpload, insuranceImageUpload])
        .then(([carImageUrl, insuranceImageUrl]) => { 
 
            newData.car_image = carImageUrl;
            newData.insuranceImage = insuranceImageUrl;
            newData.insurance_expiry_date = newData.insurance_expiry_date.toISOString();
 
            set(child(carAddRef, carId), newData)
                .then(() => {  
                })
                .catch((error) => {
                    console.error("Error saving car data:", error);  
                });
 
            if (newData.active) {
                const updateData = {
                    carType: newData.carType,
                    vehicleNumber: newData.vehicleNumber,
                    vehicleMake: newData.vehicleMake,
                    vehicleModel: newData.vehicleModel,
                    other_info: newData.other_info,
                    insurance_expiry_date: newData.insurance_expiry_date,
                    vechicle_color: newData.vechicle_color,
                    car_image: carImageUrl,
                    insuranceImage: insuranceImageUrl,
                    carApproved: newData.approved,
                    updateAt: new Date().getTime(),
                };

                update(singleUserRef(auth.currentUser.uid), updateData)
                    .then(() => { 
                    })
                    .catch((error) => {
                        console.error("Error updating user car data:", error);  
                    });
            }
        })
        .catch((error) => {
            console.error("Error during upload or save process:", error);  
        });
};
